<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#35D6ED"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs4 pt-4 text-center>
            <span
              class="mainfont"
              style="color: black; font-size: 23px; font-weight: bolder"
            >
              Category Detailed View</span
            >
            <v-flex xs11 pl-10 align-self-center>
              <v-progress-linear
                align-center
                height="4"
                color="#13736f"
                value="100"
                width="20%"
              ></v-progress-linear>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-2>
          <v-flex xs1 style="border-bottom: 6px solid grey"></v-flex>
          <v-flex xs2 style="border-bottom: 6px solid #8bc34a"></v-flex>
          <v-flex xs6 style="border-bottom: 6px solid #13736f"></v-flex>
          <v-flex xs2 style="border-bottom: 6px solid #8bc34a"></v-flex>
          <v-flex xs1 style="border-bottom: 6px solid grey"></v-flex>
        </v-layout>

        <v-layout wrap pl-3 pt-7 justify-center>
          <v-flex
            xs12
            class="mainfont"
            style="color: black; font-size: 23px; font-weight: lighter"
          >
            Name : {{ list.name }}
          </v-flex>

          <v-flex
            xs12
            class="mainfont"
            style="color: black; font-size: 23px; font-weight: lighter"
          >
            Listing Order : {{ list.listingOrder }}
          </v-flex>

          <v-flex xs2>
            <v-img contain :src="mediaURL + list.icon"> </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      preview: null,
      link: null,
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      id: this.$route.query.id,
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      formData: new FormData(),
      listingOrder: null,
      list: [],
      curid: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/view/" + this.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  
  <style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>